import { ReactionResponse, ReactionMessage } from "../components/alerts-manager/alerts-manager";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import moment from 'moment';
import { AlertsCityManage } from "../components/edit-manager/edit-manager";

//var msalI = null;
//var isLocalDebugMode = false;
//var debugUrl = "https://api-alertscity-test.azurewebsites.net";
//var debugUrl = "https://localhost:44349";
//var realUrl = "https://api.alerts.city";

declare global {
    interface Window { moment: any, FB: any }
}

// var tokenRequest = {
//   scopes: ["https://api.alerts.city/user_impersonation"]
// }

const url_PROD = 'https://api.alerts.city';
const url_LOCAL = 'https://localhost:5001';

const from_url_PROD = 'https://m.alerts.city';
const from_url_PROD_ALT = 'https://duvallalerts.com';
const from_url_LOCAL = 'http://localhost:3333';
const from_url_LOCAL2 = 'http://localhost:3334';

const MapClientToServer = {
    [from_url_PROD]: url_PROD,
    [from_url_PROD_ALT]: url_PROD,
    [from_url_LOCAL]: url_PROD,
    [from_url_LOCAL2]: url_PROD
};

let coreUrl = null;
export function getCoreUrl() {
    if (!coreUrl) {
        coreUrl = MapClientToServer[window.location.origin];
        console.log(window.location.origin + ' mapped to ' + coreUrl);
        if (!coreUrl) {
            coreUrl = MapClientToServer[window.location.origin];
            if (!coreUrl) {
                console.error('window.location.origin does not reveal server location. Falling back to prod.');
                coreUrl = url_PROD;
            }
        }
    }

    return coreUrl;
}

export function replaceUrl(url: string): string {
    const repl = getCoreUrl();
    if (url.indexOf('$api$') > -1) {
        url = url.replace('$api$', repl);
    }
    return url;
}

export async function getUrlToken(url: string, isJson: boolean = true, mime: any = 'application/json') {
    url = replaceUrl(url);

    var headers = {};
    if (isJson) {
        headers['Content-Type'] = mime;
    }

    //  try {
    const response = await fetchWithToken(url, {
        method: "GET",
        headers: headers//,
        //credentials: isLocalDebugMode ? 'omit' : 'include'
    });

    return response.json();
}

export async function postUrlToken(url: string, data: any, isJson: boolean = true) {
    url = replaceUrl(url);

    var headers = {};
    if (isJson) {
        headers['Content-Type'] = 'application/json';
    }

    //  try {
    const response = await fetchWithToken(url, {
        method: "POST",
        headers: headers,
        body: isJson ? JSON.stringify(data) : data//,
        //credentials: isLocalDebugMode ? 'omit' : 'include'
    });

    if (response === null) {
        return null;
    }

    return response.json();
}

export async function fetchWithToken(endpoint, options: any = {}) {
    const token = await getToken();
    const bearer = `Bearer ${token}`;
    if (options.headers) {
        options.headers['Authorization'] = bearer;
    } else {
        options.headers = {
            Authorization: bearer
        }
    }
    if (!options.credentials) {
        // required for CORS as any request with Authorization header is a "non-simple" request
        //options.credentials = 'include';
    }
    return await fetch(endpoint, options);
}

// export isLoggedIn = false;
// export users = { id: '', name: '', email: '', picture: { data: { url: '' } } };

export function fbLogin() {
    window.FB.login(['public_profile', 'email'])
        .then(res => {
            if (res.status === 'connected') {
                console.log('in');
                this.isLoggedIn = true;
                this.getUserDetail(res.authResponse.userID);
            } else {
                this.isLoggedIn = false;
                console.log('else');
            }
        })
        .catch(e => console.log('Error logging into Facebook', e));
}

async function getToken() {
    return "";
    // return new Promise((resolve, reject) => {
    //   // is user logged in?
    //   if (msalI === null) {
    //     msalI = getMsalInstance();
    //   }

    //   if (msalI.getAccount()) {
    //     // if so, try acquiring token silently first (works if token cached)
    //     msalI.acquireTokenSilent(tokenRequest)
    //       .then(response => {
    //         resolve(response.accessToken);
    //       })
    //       .catch(err => {
    //         if (err.name == "InteractionRequiredAuthError") {
    //           // if silent token acquire didn't work, use popup
    //           return msalI.acquireTokenPopup(tokenRequest)
    //             .then(response => {
    //               resolve(response.accessToken);
    //             })
    //             .catch(reject)
    //         } else {
    //           reject(err);
    //         }
    //       });
    //   } else {
    //     // user not logged in. log in and try again
    //     msalI.loginPopup(tokenRequest)
    //       .then(() => {
    //         resolve(getToken())
    //       })
    //       .catch(err => {
    //         // login error
    //         reject(err);
    //       })
    //   }
    // });
}

export async function getUrl(url: string, isJson: boolean = true, mime: any = 'application/json') {
    const promise = new Promise<any>((resolve, reject) => {
        var xhr = new XMLHttpRequest();

        url = replaceUrl(url);

        // if (url.indexOf("$api$") > -1) {
        //   //var isLocal = location.hostname === "localhost" || location.hostname === "127.0.0.1";
        //   //var repl = isLocal ? "http://localhost:5628" : ("https://api.improvethe.world");
        //   var repl = isLocalDebugMode ? debugUrl : realUrl;
        //   url = url.replace("$api$", repl);
        // }

        xhr.open('GET', url);
        if (isJson) {
            xhr.setRequestHeader('Content-Type', mime);
        }
        xhr.onload = function () {
            if (xhr.status === 200) {
                resolve(JSON.parse(xhr.responseText));
            }
            else {
                reject(xhr);
            }
        };
        xhr.send();
    });

    return promise;
}

export async function deleteUrl(url: string, data: any, isJson: boolean = true) {
    const promise = new Promise<any>((resolve, reject) => {
        var xhr = new XMLHttpRequest();

        url = replaceUrl(url);

        // if (url.indexOf("$api$") > -1) {
        //   //var isLocal = location.hostname === "localhost" || location.hostname === "127.0.0.1";
        //   //var repl = isLocal ? "http://localhost:5628" : ("https://api.improvethe.world");
        //   var repl = isLocalDebugMode ? debugUrl : realUrl;
        //   url = url.replace("$api$", repl);
        // }

        xhr.open('DELETE', url);
        if (isJson) {
            xhr.setRequestHeader('Content-Type', 'application/json');
        }
        xhr.onload = function () {
            if (xhr.status === 200) {
                resolve(JSON.parse(xhr.responseText));
            }
            else {
                reject(xhr);
            }
        };
        xhr.send(isJson ? JSON.stringify(data) : data);
    });

    return promise;
}

export async function postUrl(url: string, data: any, isJson: boolean = true) {
    const promise = new Promise<any>((resolve, reject) => {
        var xhr = new XMLHttpRequest();

        url = replaceUrl(url);
        // if (url.indexOf("$api$") > -1) {
        //   //var isLocal = location.hostname === "localhost" || location.hostname === "127.0.0.1";
        //   //var repl = isLocal ? "http://localhost:5628" : ("https://api.improvethe.world");
        //   var repl = isLocalDebugMode ? debugUrl : realUrl;
        //   url = url.replace("$api$", repl);
        // }

        xhr.open('POST', url);
        if (isJson) {
            xhr.setRequestHeader('Content-Type', 'application/json');
        }
        xhr.onload = function () {
            if (xhr.status === 200) {
                resolve(JSON.parse(xhr.responseText));
            }
            else {
                reject(xhr);
            }
        };
        xhr.send(isJson ? JSON.stringify(data) : data);
    });

    return promise;
}

export function loadScript(url, callback) {
    var script = document.createElement("script") as any;
    script.type = "text/javascript";
    if (script.readyState) {  // only required for IE <9
        script.onreadystatechange = function () {
            if (script.readyState === "loaded" || script.readyState === "complete") {
                script.onreadystatechange = null;
                callback();
            }
        };
    } else {  //Others
        script.onload = function () {
            callback();
        };
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
}

export function dateAs(date: Date, format: string) {
    return window.moment(date).format(format);
}

export function timeAgo(date: Date) {
    return window.moment(date).fromNow();
}

export function floatAs(float: number, format: string) {
    if (format[0] === 'f') {
        return float.toFixed(parseInt(format.substring(1)));
    }
}

export function shortTime(date: Date) {
    return window.moment(date).format('LT');
}

export function shortDateTime(date: Date) {
    return window.moment(date).format('l') + ' ' + window.moment(date).format('LT');
}

export function doEvent(eventName: string, id: string = null) {
    var event = document.createEvent('Event');
    if (id === null) {
        console.debug('sent:event:' + eventName);
        event.initEvent(eventName, true, true);
        window.dispatchEvent(event);
    } else {
        console.debug('sent:custom event:' + eventName);
        var element = document.getElementById(id);
        var ev2 = new Event(eventName);
        element.dispatchEvent(ev2);
    }
}

export function doEvent1(eventName: string, params: any) {
    var event = new CustomEvent(eventName, { 'detail': params });
    window.dispatchEvent(event);
}

export function hasClass(element: Element, className: string) {
    if (!element || !element.classList) {
        return false;
    }
    return element.classList.contains(className);
}

export function toggleClass(element: Element, className: string, addIfTrueRemoveIfFalse: any = null) {

    if (typeof element === 'undefined' || element === null) {
        log('WARNING: missing element', 'warning,trace', 'utils');
        return;
    }

    var toRemove = (typeof addIfTrueRemoveIfFalse === 'boolean' ? !addIfTrueRemoveIfFalse : hasClass(element, className));
    var toAdd = (typeof addIfTrueRemoveIfFalse === 'boolean' ? addIfTrueRemoveIfFalse : !hasClass(element, className));

    if (toRemove && hasClass(element, className)) {
        element.classList.remove(className);
    } else if (toAdd && !hasClass(element, className)) {
        element.classList.add(className);
    }
}

export function setDateString(dateTime: string, date: string): string {
    dateTime = date.substring(0, 10) + dateTime.substring(10);
    return dateTime;
}

export function prefixName(factor?: number): string {
    if (typeof factor === 'number') {
        switch (factor) {
            case 1 / 1000: return "k";
            case 1000: return "m";
            case 1000000: return "µ";
            case 1000000000: return "n";
            case 1000000000000: return "p";
        }
    }

    return "";
}

export function ensureDate(date: any): Date {
    switch (typeof date) {
        case 'string': return new Date(Date.parse(date));
        case 'number': return new Date(date);
        case 'object': return date;
    }

    return null;
}

export function doLater(fn: any, time: number = 250) {
    window.setTimeout(fn, time);
}

export function getSubdata(data: any, path: string) {
    var parts = path.split('.');
    var value = data;
    for (var i = 0; i < parts.length; i++) {
        value = value[parts[i]];
    }
    return value;
}

export function isObject(obj) {
    var type = typeof obj;
    return type === 'function' || type === 'object' && !!obj;
};

export function iterationCopy(src) {
    let target = {};
    for (let prop in src) {
        if (src.hasOwnProperty(prop)) {
            // if the value is a nested object, recursively copy all it's properties
            if (isObject(src[prop])) {
                target[prop] = iterationCopy(src[prop]);
            } else {
                target[prop] = src[prop];
            }
        }
    }
    return target;
}

export const groupBy = key => array =>
    array.reduce((objectsByKeyValue, obj) => {
        const value = obj[key];
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
        return objectsByKeyValue;
    }, {});

declare global {
    interface Window { doEvent, logFilter, hadLogError }
}

window.hadLogError = false;

export function log(msg: any, type: string = '', src: string = '') {
    try {
        if (window.logFilter) {
            var parts = window.logFilter.split(',');
            var show = false;
            for (var i = 0; i < parts.length; i++) {
                show = show || src === parts[i] || (typeof type === 'string' && type.indexOf(parts[i]) > -1) || (typeof msg === 'string' && msg.indexOf(parts[i]) > -1);
            }

            if (show) {
                console.log(src + ':' + type + ':' + '(' + msg + ')');
            }
        }
        else if (typeof window.logFilter !== 'boolean') {
            console.log(src + ':' + type + ':(' + msg + ')');
        }

        if (type.indexOf('aix') > -1) {
            window.ai.trackEvent({ name: msg })
        }
    } catch (e) {
        if (!window.hadLogError) {
            logError(e, 'error in log: ' + JSON.stringify({ msg: msg, type: type, src: src }));
        }
    }
}

export function logError(e: Error, msg: string = null) {
    console.log('error:' + e.message + ' /// ' + e.stack + (msg ? (' /// ' + msg) : ''));
    window.ai.trackException({ error: e, severityLevel: SeverityLevel.Error, properties: { msg: msg } });
}

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function hashCode(str) {
    var hash = 0, i, chr;
    if (str.length === 0) return hash;
    for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
};

export function getAllItemsWhere(data: ReactionResponse, qualification: (m: ReactionMessage) => boolean = null): ReactionMessage[] {
    if (!data) {
        return [];
    }

    if (typeof qualification === 'undefined' || qualification == null) {
        qualification = ({ }) => true;
    }

    data.statuses.messages.map(m => m._source = 'statuses');
    data.roads.messages.map(m => m._source = 'roads');
    data.cameras.messages.map(m => m._source = 'cameras');
    data.weather.messages.map(m => m._source = 'weather');
    data.helpfulLinks.messages.map(m => m._source = 'statuses');

    return [].concat(
        data.statuses.messages.filter(qualification),
        data.roads.messages.filter(qualification),
        data.cameras.messages.filter(qualification),
        data.weather.messages.filter(qualification),
        data.helpfulLinks.messages.filter(qualification));
}

export function setupTimer(options: any) {
    var onOverallFocus = () => {
        log(options.nickname + ':got focus', 'trace', options.page);
        if (options.getset()) {
            options.action();
        }
    };

    var page = document.getElementById(options.pageId);
    if (page) {
        page.addEventListener('focus', onOverallFocus);
    }

    window.addEventListener('focus', onOverallFocus);

    window.addEventListener(options.listener, () => {
        log('received ' + options.listener, 'events', options.page);
        options.action();
    });

    return window.setInterval(() => {
        options.getset(true);
        options.action();
    }, options.timing);

}

export function zmoment(date: Date | string): moment.Moment {
    if (typeof date !== 'string' || date.toString().indexOf('Z') > -1) {
        return moment(date);
    } else {
        return moment(date + 'Z');
    }
}

export function hasValue(obj: any) {
    return typeof (obj) !== 'undefined' && obj !== null && (typeof (obj) === 'string' ? obj.length > 0 : true);
}

export function normalizeHours(monToSunHours: string[], suffix: string = '') {
    var totalLen = 0;
    for (var i = 0; i < 7; i++) {
        totalLen += hasValue(monToSunHours[i]) ? monToSunHours[i].length : 0;
    }

    if (totalLen === 0) {
        return suffix;
    }

    var days = ["Mon", "Tues", "Wed", "Thurs", "Fri", "Sat", "Sun"];

    var list = [];

    for (var i = 0; i < 7; i++) {
        var match = -1;
        //normalize here
        var text = hasValue(monToSunHours[i]) ? monToSunHours[i] : "";
        text = text.replace(/ to /g, "-").replace(/ /g, "").toLowerCase();
        text = text.replace(/m/g, "").replace(/noon/g, "12p");

        for (var j = 0; j < list.length; j++) {
            if (list[j].key === text) {
                match = j;
                break;
            }
        }

        if (match === -1) {
            list.push(
                {
                    count: 1,
                    key: text,
                    days: []
                }
            );
            match = list.length - 1;
        }

        list[match].count++;
        list[match].days.push(days[i]);
    }

    if (list.length === 1) {
        return ("daily " + list[0].key + " " + suffix).trim();
    }

    var hours = "";
    //var hrSet = list;
    var hrSet = list.sort((a, b) => b.count - a.count);
    for (var i = 0; i < hrSet.length; i++) {

        if (hrSet[i].key.length === 0) {
            continue;
        }

        var dayRange = hrSet[i].days[0];
        var endRange = '';
        var midSymbol = '';
        if (hrSet[i].days.length > 1) {
            var yesterdayIndex = days.indexOf(dayRange);
            for (var j = 1; j < hrSet[i].days.length; j++) {
                var todayIndex = days.indexOf(hrSet[i].days[j]);
                if (todayIndex - yesterdayIndex === 1) {
                    endRange = hrSet[i].days[j];
                    midSymbol = "-";
                }
                else {
                    if (endRange.length > 0) {
                        dayRange += midSymbol + endRange + ", ";
                        midSymbol = ", ";
                        dayRange += hrSet[i].days[j];
                        endRange = "";
                    }
                    else {
                        midSymbol = ", ";
                        dayRange += midSymbol + hrSet[i].days[j];
                        endRange = "";
                    }
                }

                yesterdayIndex = todayIndex;
            }

            if (endRange.length > 0) {
                dayRange += midSymbol + endRange;
            }
        }

        if (hours.length > 0) {
            hours += ", ";
        }

        if (dayRange.length > 2 && dayRange.substring(dayRange.length - 2, 2) === ", ") {
            dayRange = dayRange.substr(0, dayRange.length - 2);
        }

        hours += (dayRange + ": " + (hrSet[i].key.replace(/,/g, ", ")) + " " + suffix).trim();
    }

    return hours;
}

export function ReactionMessageToAlertsCityManage(rm: ReactionMessage): AlertsCityManage {
    var acm: AlertsCityManage = {
        id: rm.id,
        dataAspect: rm.dataAspect,
        title: rm.title,
        specialData: JSON.parse(rm.data.specialData),
        dataTemplate: null,
        order: rm.order,
        dataProperty: rm.dataProperty,
        isActive: true,
        areaMiles: 7
    };

    return acm;
}
